<!--电能设置--基本设置---->
<template>
  <div id="reductiontarget"  v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="headertitle">
      <!-- 削减目标 -->
      <span>{{$t('energy.enedata240')}}</span>
    </div>
    <div class="header">
      <div class="header-left">
          <!-- 楼层/租户 -->
        <!-- <div class="header-condition">
          <span>{{$t('energy.enedata241')}}：</span>
          <a-select v-model="tenantId" style="width: 150px" @change="handleTenantChange">
            <a-select-option
              v-for="(itme, index) in tenantList"
              :key="index"
              :value="itme.id"
            >
              {{ itme.name }}
            </a-select-option>
          </a-select>
        </div> -->
        <div class="header-condition">
          <!-- 年度 -->
          <span>{{$t('energy.enedata046')}}：</span>
          <a-select v-model="selYear" style="width: 150px">
            <a-select-option
              v-for="(itme, index) in yearList"
              :key="index"
              :value="itme"
            >
              {{ itme }}
            </a-select-option>
          </a-select>
        </div>
        <!--  包括建筑群 包括建筑 包括租户 -->
         <div class="header-condition">
           <a-checkbox :checked="chkSitegr" v-model="chkSitegr" :disabled="chkSitegrDisable">
            {{$t('energy.enedata242')}}
           </a-checkbox>
           <a-checkbox :checked="chkSite" v-model="chkSite" :disabled="chkSiteDisable">
             {{$t('energy.enedata243')}}
           </a-checkbox>
           <a-checkbox :checked="chkTenant" v-model="chkTenant" :disabled="chkTenantDisable">
             {{$t('energy.enedata089')}}
           </a-checkbox>
         </div>
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="search()"> {{$t('energy.enedata006')}}</a-button>
      </div>
    </div>
    <div class="main">
        <a-table :columns="columns" :data-source="reductionTargets" bordered  :pagination="false" >
            <template   v-for="col1 in ['reductionTarget']" :slot="col1"  slot-scope="text, record"  >
              <div :key="col1">
                  <a-input-number style="width:100%;"  v-model="record.reductionTarget" :class="record.vail"/>
              </div>
            </template>
        </a-table>
    </div>
  <div style="margin-top:10px;">
    <!-- <p>※当客户或建筑组选择时，该阶层以下的全部削减目标（除了租户）将被覆盖。 另外，选择【包括租户】的话，那个楼层以下包括租户的削减目标将全部被覆盖，请注意。 </p>
    <p>※削减目标是自动继承前一年度的数据。因此，如果需要变更的话，请每年重新设定。 </p> -->
    <p>{{$t('energy.enedata244')}}</p>
    <p>{{$t('energy.enedata245')}}</p>
  </div>

    <div class="button">
      <a-button type="primary" @click="saveReductionTarget">{{$t('energy.enedata173')}}</a-button>
    </div>
  </div>
</template>

<script>
import { getReductionTargetData  , saveReductionTargetData } from "../../../api/energy";
import { getTenantList } from "../../../api/door";
const columns = [];

export default {
  name: "reductiontarget",
  data() {
      
    return {
      loading: false,
      selYear:'',
      tenants:[],
      yearList:[],
      tenantList:[],
      tenantId: 0,
      reductionTargets:null,
      errorMessage:[
           this.$t('energy.enedata240')                      // 0
         , this.$t('energy.enedata180') //"数据注册成功。"                            // 1
         , this.$t('energy.enedata181') //"数据注册失败。"                            // 2
         , this.$t('energy.enedata250') //"确认要保存所设置的削减目标吗？"                  // 3 
         , this.$t('energy.enedata251') //"如果将削减目标值更改为空白井进行注册，则削减目标和上次修改如期将被删除，确定要注册吗？"                  //4 
         ,""        
        ] ,
      columns:[
          {title: 'NO.'		                             , dataIndex: 'no'		 ,width:50},
          {title: this.$t('energy.enedata046')          , dataIndex: 'year',width:95,},
          {title: this.$t('energy.enedata240')+'（%）' , dataIndex: 'reductionTarget',  scopedSlots: { customRender: 'reductionTarget' },width:95,},
          {title: this.$t('energy.enedata246')+'（MJ）', dataIndex: 'energyUse',width:95,},
          {title: this.$t('energy.enedata247')+'（%）' , dataIndex: 'annualComparison',  scopedSlots: { customRender: 'annualComparison' },width:95,},
          {title: this.$t('energy.enedata248')+'（MJ）', dataIndex: 'targetValue',  scopedSlots: { customRender: 'targetValue' },width:95,},
          {title: this.$t('energy.enedata249')         , dataIndex: 'lastUpdDate',  scopedSlots: { customRender: 'lastUpdDate' },width:95,},
      ],
      chkSitegr:false,
      chkSitegrDisable:false,
      chkSite:false,
      chkSiteDisable:false,
      chkTenant:false,
      chkTenantDisable:false,
    };
  },
  computed: {
    
  },
  activated(){
    // console.log("activated",this.$store.getters.clientId);
    // getTenantList(this.$route.query.id).then((res) => {
    //     this.tenants = res.data;
    //     this.tenantList = [];
    //     this.tenantList.push({id:0,name:"　"});
    //     for (let i = 0; i < this.tenants.length; i++) {
    //         let item =  tenants[i];
    //         let t = {id:item.id,name:item.name};
    //         this.tenantList.push(t);
    //     }
    // }).catch((err) => {
    //         this.loading = false;
    //         console.log(err);
    // });

    
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadReductionTarget(1,false,false,false);
    }
  },
  mounted() { 
    // console.log('init s');
    // let tenants = JSON.parse(sessionStorage.getItem('siteTenant'));
    // this.tenantList = [];
    // this.tenantList.push({id:0,name:"　"});
    // for (let i = 0; i < tenants.length; i++) {
    //     let item =  tenants[i];
    //     let t = {id:item.id,name:item.name};
    //     this.tenantList.push(item);
    // }
    //this.loadReductionTarget(1,false,false,false);
    // console.log('init e');
  },
  methods:{
    handleTenantChange(){
      if(this.tenantId != 0){
        this.chkTenant = false;
        this.chkTenantDisable = true;
      }else{
        this.chkTenant = false;
        this.chkTenantDisable = false;
      }

    },
    search(){
      this.loadReductionTarget(1,this.chkSitegr,this.chkSite,this.chkTenant);
    },
    loadReductionTarget(action,chkSitegr,chkSite,chkTenant){
      let params = {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            selYear:this.selYear,
            tenantId: this.tenantId,
            chkSitegr: chkSitegr,
            chkSite: chkSite,
            chkTenant: chkTenant,
        };
        console.log(params);
        this.loading = true;
    
        getReductionTargetData(params)
        .then((res) => {
            console.log(res);
            this.loading = false;
            if(res.errorCode == '00'){
                this.selYear = res.data.selYear;
                this.yearList = res.data.yearList;
                this.chkSitegr 		   = res.data.chkSitegr			;
                this.chkSitegrDisable    = res.data.chkSitegrEnable == "disabled"   ;
                this.chkSite 		       = res.data.chkSite           ;
                this.chkSiteDisable      = res.data.chkSiteEnable  == "disabled"    ;
                this.chkTenant           = res.data.chkTenant         ;
                this.chkTenantDisable    = res.data.chkTenantEnable  == "disabled"   ;
                
                if(res.data.reductionTargetDatas!=null){
                    let arr = [];
                    for (let i = 0; i < res.data.reductionTargetDatas.length; i++) {
                        let item =  res.data.reductionTargetDatas[i];
                        item.no = i+1;
                        item.key = i+1;
                        item.vail = '';
                        arr.push(item);
                    }
                    this.reductionTargets =arr;
                } 
            }
            
        })
      .catch((err) => {
            this.loading = false;
            console.log(err);
      });
    },
    saveReductionTarget(){
      let err = 0;
      let paramArr = [];

      let msg = this.errorMessage[3];
      for (let k = 0; k < this.reductionTargets.length; k++) {
        this.reductionTargets[k].vail = '';
        let rt = parseInt(this.reductionTargets[k].reductionTarget);
        // console.log("rt"+rt);
        if(isNaN(rt) || rt<0){
           this.reductionTargets[k].vail = 'error';
           err = 1;
           msg = this.errorMessage[4];
        }
        var item ={
          reductionTarget:this.reductionTargets[k].reductionTarget,
          year:this.reductionTargets[k].year
        }
        paramArr.push(item);
      }

      this.$confirm({
        title: msg,
        centered: true,
        onOk: () => {
              this.loading = true;
              let params = {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: this.tenantId,
                chkSitegr: this.chkSitegr,
                chkSite: this.chkSite,
                chkTenant: this.chkTenant,
                selYear: this.selYear,
                targetValues:paramArr
              }
              console.log(params);
              saveReductionTargetData(params)
              .then((res) => {
                let msg = '';
                if(res.errorCode=="00"){
                  msg = this.errorMessage[0]+this.errorMessage[1];
                  this.search();
                  this.$message.success(msg);
                }else{
                    msg = this.errorMessage[0]+this.errorMessage[2];
                  this.$message.error(msg);
                }
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
             }
       });
    }
  },
};
</script>

<style scoped>
#reductiontarget {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  overflow-y: auto;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'

}
.headertitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.headertitle::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    box-shadow: 0px 3px 8px #e7e6e6;
}
.button{
    display: flex;
    justify-content: center;
    margin-top: 30px    ;
}
input {
  width: 120px;
}
button {
  width: 120px;
  margin: 0 5px;
}
.error{
  border: 1px solid #FF0000;
}
.dropdown-select-item{height: 22px;}
</style>